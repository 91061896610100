.section-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  .container-product {
    display: flex;
    width: 80%;
    padding: 40px 0;
    gap: 40px 20px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 90%;
      padding: 16px 0;
      align-items: center;
      gap: 20px;
    }
    .title {
      font-size: 3.812rem;
      text-align: center;
      margin: 0 auto;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      color: #082a5b;
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }
    .container-card {
      padding: 40px 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 32px 20px;

      @media (max-width: 768px) {
        gap: 20px;
      }
    }
  }
}
