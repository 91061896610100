.surroundingcontainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 40px 0;
  background: linear-gradient(to bottom, #001537, #39a182);
  @media (max-width: 768px) {
    padding: 16px 0;
  }
  .title {
    color: #b4cbff;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 53px;
    padding: 25px 0 25px 0;
    margin-bottom: 40px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
  .content {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    @media (max-width: 768px) {
      gap: 0;
      display: none;
      flex-direction: column;
    }
    .center {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      .imgcontainer {
        overflow: hidden;
      }
      .imgcontent {
        width: 100%;
        height: 322px;
        object-fit: cover;
        filter: brightness(80%);
        transform: scale(1.3);
        @media (max-width: 768px) {
          width: 90%;
        }
      }
      .imgdes {
        height: 120px;
        margin-top: -60px;
        z-index: 1;

        span {
          font-family: "Open Sans";
          color: white;
          font-size: 20px;
          text-shadow: 2px 2px black;
          padding: 9px 0;
          width: 100%;
        }
      }
    }
  }
  .content1 {
    display: none;
    width: 90%;
    .slick-dots {
      bottom: 0px;
    }
    .slick-next {
      right: 25px;
    }
    .slick-prev {
      z-index: 1;
      left: 25px;
    }
    @media (max-width: 768px) {
      gap: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      .imgcontainer {
        overflow: hidden;
      }
      .imgcontent {
        @media (max-width: 768px) {
          width: 100%;
          height: 263px;
          object-fit: cover;
        }
      }
      .imgdes {
        height: 120px;
        margin-top: -50px;

        text-align: center;
        @media (max-width: 768px) {
          margin-bottom: -35px;
        }

        span {
          font-family: "Open Sans";
          color: white;
          font-size: 20px;
          text-shadow: 1px 1px 15px black;
        }
      }
    }
  }
}
